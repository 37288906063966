<template>
  <div class="grid mt-3">
    <div class="col-12">
      <Button icon="pi pi-chevron-left" label="voltar" class="p-button-secondary mb-3" @click="$router.push('/')" />
    </div>
    <div class="col-12 pedido-mensagem" v-if="pedidos.length == 0">
      <strong class="block mb-5">Você ainda não realizou nenhum pedido!</strong>
      <Button label="Vamos comprar algo?" @click="$router.push('/')" />
    </div>
    <div :class="'col-' + 12/1 + ' md:col-' + 12/2" v-for="i of pedidos" :key="i.id">
      <component :is="'Pedidos'" :value="i"></component>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pedidos: [],
      loading: false
    };
  },
  watch: {
    "$root.pedidos": {
      handler: function (pedidos) {
        if (typeof pedidos != "undefined" && pedidos != null) {
          this.pedidos = Object.values(pedidos);
        } else {
          // Limpa Tela
          this.pedidos = [];

        }

        this.loading = false;
      },
      deep: true,
      immediate: true,
    },
  },

}
</script>

<style>
</style>